import staticContent from '@/components/static-content/index.vue';
import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'video',
  data() {
    return {}
  },
  components: {
    staticContent,
    emptyData
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  created() {
    this.fetchVideoData(this.$route.params.slug);
  },
  computed: {
    ...mapGetters({
      videoData: 'videos/videoData',
      isLoadingVideo: 'videos/isLoadingVideo',
    }),
  },
  methods: {
    ...mapActions({
      fetchVideoData: `videos/VIDEOS_DATA_GET`,
    }),
  },
}
